var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.containerStyle)},[_c('button',{class:{
      success: _vm.success,
      danger: _vm.danger,
      primary: _vm.primary,
      tabs: _vm.tabs,
      firstTab: _vm.firstTab,
      lastTab: _vm.lastTab,
      hover: _vm.hover,
      margin: _vm.margin,
      single: _vm.single,
      plus: _vm.plus,
      'button d-flex justify-content-center align-items-center': _vm.type
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('clickBtn')}}},[(_vm.img)?_c('img',{staticClass:"image",staticStyle:{"margin-right":"5px"},attrs:{"src":_vm.img,"alt":""}}):_vm._e(),(_vm.buttonName)?_c('span',{class:{ 'small-text': _vm.smallText, 'medium-text': _vm.mediumText, 'dark-color': _vm.darkColor }},[_vm._v(" "+_vm._s(_vm.buttonName)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }