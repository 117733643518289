






































































import Vue from 'vue'
import Table from '@/components/integration/Table/IntegrationTable.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import IntegrationTableGoogle from '@/components/integration/Table/IntegrationTableGoogle.vue'
import {
  FACEBOOK_SERVICE,
  SNAPCHAT_SERVICE,
  TIKTOK_SERVICE,
  LINKEDIN_SERVICE,
  GOOGLE_SERVICE,
  TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'
import Card from '@/components/Card.vue'
import { mapActions, mapGetters } from 'vuex'
import _, { isArray, isObject } from 'lodash'
import IListIntegrationAccount from '@/models/Integrations/IListIntegrationAccount'

export default Vue.extend({
  components: {
    Card,
    Table,
    BasePagePreloader,
    IntegrationTableGoogle,
    Button
  },
  name: 'SettingsAdServiceIntegrations',
  props: {
    service: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      adAccountsErrors: [] as Array<{
        ad_account_id: string,
        fields: Array<'timezone' | string>
      }>,
      adAccounts: [] as IListIntegrationAccount[],
      selectedAdAccounts: [] as IListIntegrationAccount[],
      loading: true,
      perPage: 10
    }
  },
  beforeCreate () {
    const url = new URL(window.location.href)
    if (url.searchParams.get('success') === '0') {
      this.$router.push({ name: 'Integrations' })
    }
  },
  created () {
    if (this.$route.query.per_page) {
      this.perPage = this.$route.query.per_page
    }
    this.setService(this.service)
    this.getAdAccounts().then(() => {
      this.adAccounts = _.cloneDeep(this.getListAdAccounts(this.service).data)
        .map(a => ({ ...a, select_disabled: a.select_disabled || a.is_select }))
      this.selectedAdAccounts = this.adAccounts.filter(a => a.is_select)
      this.loading = false
    }).catch(error => {
      console.log(error)
    })
  },

  computed: {
    ...mapGetters('integrations', [
      'getSnapchat', 'getFacebook', 'getTiktok',
      'getLinkedin', 'getGoogle', 'getTwitter']),
    ...mapGetters('adService', ['getServiceTitle']),
    counter () {
      return this.selectedAdAccounts.length
    },
    urlObject () {
      return new URL(window.location.href)
    },
    d_token () {
      return this.urlObject.searchParams.get('d_token')
    },
    account_manager () {
      return this.getListAdAccounts(this.service).data.filter((el) => el.is_manager === true)
    },
    service_name () {
      return this.getServiceTitle
    },
    addAccountsButtonDisabled () {
      return this.counter === 0
    },
    addAdAccountsButtonText () {
      return this.counter === 0 ? 'Add Ad Account' : `(${this.counter} ) Add Ad Account`
    },
    pagination () {
      return this.getListAdAccounts(this.service)?.meta?.pagination
    }
  },
  methods: {
    ...mapActions('integrations', ['create_ad_account', 'load_ad_accounts']),
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    ...mapActions('adService', ['setService']),
    // * отдаем запрос на добавление аккаунта
    addAdAccount (): void {
      if (this.isInvalidData()) return

      const accounts = {}

      this.selectedAdAccounts
        .forEach((account: IListIntegrationAccount) => {
          const id: string = account.id
          const name: string = account.name
          accounts[id] = this.service === LINKEDIN_SERVICE ? {
            on: true,
            name: name,
            timezone: account.timezone
          } : {
            on: true,
            name: name
          }
        })
      this.create_ad_account({
        service: this.service,
        data: {
          accounts: accounts,
          d_token: this.d_token
        }
      }).then(() => {
        this.addNotification({ type: 'success', body: `Your ${this.getServiceTitle} integration has been successfully completed.` })
        this.$router.push({ name: 'Integrations', required: true })
      }).catch((error) => {
        error.response?.data && this.addExceptions(error.response.data)
      })
    },

    // * добавляем данные юзера в нашу data
    toggleAccount (id: number | string): void {
      let account = null
      this.adAccounts.every(a => {
        if (isObject(a.clients)) {
          account = Object.values(a.clients).find((c: {id: number | string}) => c.id === id)
          if (account) {
            return false
          }
        }
        if (a.id === id) {
          account = a
          return false
        }
        return true
      })
      if (!this.selectedAdAccounts.find(a => a.id === id)) {
        this.selectedAdAccounts.push(account)
      } else {
        this.selectedAdAccounts = this.selectedAdAccounts.filter(a => a.id !== id)
      }
    },

    addError (id: string, error: string): void {
      const index = this.adAccountsErrors.findIndex(_ => _.ad_account_id === id)

      if (index !== -1) {
        if (!this.adAccountsErrors[index].fields.includes(error)) {
          this.adAccountsErrors[index].fields.push(error)
        }
      } else {
        this.adAccountsErrors.push({
          ad_account_id: id,
          fields: [error]
        })
      }
    },

    removeError (id: string, error: string) {
      const index = this.adAccountsErrors.findIndex(_ => _.ad_account_id === id)

      if (index !== -1) {
        if (this.adAccountsErrors[index].fields.includes(error)) {
          if (this.adAccountsErrors[index].fields.length === 1) {
            this.adAccountsErrors = this.adAccountsErrors.filter(_ => _.ad_account_id !== id)
          } else {
            this.adAccountsErrors[index].fields.filter(_ => _ !== error)
          }
        }
      }
    },

    isInvalidData (): boolean {
      return (this.service === LINKEDIN_SERVICE ? this.isInvalidTimezone() : false)
    },

    isInvalidTimezone (): boolean {
      const invalids = this.selectedAdAccounts.filter(_ => !_.timezone)

      if (invalids.length) {
        for (const invalid of invalids) {
          this.addError(invalid.id, 'timezone')
        }
        this.addNotification({ body: 'Timezone not specified', type: 'danger' })
      }

      return !!invalids.length
    },

    async getAdAccounts (page = 1): Promise<void> {
      this.loading = true
      await this.load_ad_accounts({
        service: this.service,
        data: {
          by_service: true,
          t: this.d_token,
          page: page,
          per_page: this.perPage,
          raw_disabled_text: true
        }
      }).then(() => {
        this.adAccounts = _.cloneDeep(this.getListAdAccounts(this.service).data)
          .map(a => ({ ...a, select_disabled: a.select_disabled || a.is_select }))
        this.selectedAdAccounts = this.selectedAdAccounts.concat(
          this.adAccounts.filter(a => a.is_select && !this.selectedAdAccounts.find(sa => sa.id === a.id))
        )
        this.loading = false
      }).catch(error => {
        error.response?.data && this.addExceptions(error.response.data)
      })
    },
    getListAdAccounts (service: string) {
      switch (service) {
        case FACEBOOK_SERVICE:
          return this.getFacebook
        case SNAPCHAT_SERVICE:
          return this.getSnapchat
        case TIKTOK_SERVICE:
          return this.getTiktok
        case LINKEDIN_SERVICE:
          return this.getLinkedin
        case GOOGLE_SERVICE:
          return this.getGoogle
        case TWITTER_SERVICE:
          return this.getTwitter
      }
    },
    selectTimezone ({ id, timezone }: { id: string, timezone: string }) {
      const index = this.selectedAdAccounts.findIndex(_ => _.id === id)

      if (index !== -1) {
        this.removeError(id, 'timezone')
        this.selectedAdAccounts[index].timezone = timezone
      }
    }
  }
})
