





































import Vue from 'vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import * as AccountConstants from '@/constants/AdAccount'
export default Vue.extend({
  components: {
    BaseLabelTag
  },
  props: {
    service: {
      required: true,
      type: String
    },
    pages: {
      required: true,
      type: Array
    },
    selectedPages: {
      type: Array
    }
  },
  data () {
    return {
      page: 1,
      componentKey: 0,
      mainSelect: false
    }
  },
  watch: {
    selected () {
      this.rename()
    }
  },
  methods: {
    togglePage (page, ev) {
      console.log('ev', ev)
      this.$emit('toggle-page', {
        page: {
          name: page.attributes.name,
          fb_id: page.id,
          is_published: page.attributes.is_published,
          leadgen_tos_accepted: page.attributes.leadgen_tos_accepted
        },
        event: ev
      })
    },

    async switchPage (pageNum: string) {
      this.page = pageNum || 1
      this.$emit('page', this.page)
    },
    userAccountStatusTitle (account) {
      return AccountConstants.STATUS_TITLE[account.status]
    }
  }
})
