



























import Vue from 'vue'
export default Vue.extend({
  name: 'ButtonBackTickets',
  props: {
    tabs: Boolean,
    firstTab: Boolean,
    lastTab: Boolean,
    success: Boolean,
    danger: Boolean,
    primary: Boolean,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    smallText: Boolean,
    mediumText: Boolean,
    darkColor: Boolean,
    hover: Boolean,
    margin: Boolean,
    single: Boolean,
    plus: Boolean,
    buttonName: {
      type: String,
      required: false,
      default: ''
    },
    containerStyle: {
      required: false,
      default: ''
    },
    type: {
      default: true
    },
    img: String
  }
})
