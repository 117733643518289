





























































import VSelectTimezone from '@/components/BaseComponents/VSelect/VSelectTimezone.vue'
import { LINKEDIN_SERVICE } from '@/constants/FbAutomatedRule'
import Vue from 'vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import BasePagination from '@/components/BaseComponents/BasePagination/BasePagination.vue'
import * as AccountConstants from '@/constants/AdAccount'
import { Timezone, TIMEZONES } from '@/constants/Timezones'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'IntegrationTable',
  components: {
    VSelectTimezone,
    BaseLabelTag,
    BasePagination
  },
  props: {
    selected_ad_accounts: {
      required: true,
      type: Array
    },
    service: {
      required: true,
      type: String
    },
    ad_accounts: {
      required: true,
      type: Array
    },
    pagination: {
      required: true,
      type: Object
    },
    errors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      LINKEDIN_SERVICE,
      page: 1,
      componentKey: 0,
      mainSelect: false,
      TIMEZONES
    }
  },
  watch: {
    selected () {
      this.rename()
    }
  },
  computed: {
    accounts () {
      if (this.service === 'google') {
        return this.ad_accounts.filter(el => el.is_manager === false)
      }
      return this.ad_accounts
    }
  },
  methods: {
    ...mapActions('integrations', ['updateAdAccountTimezone']),
    tooltipData (account) {
      if (account.select_disabled && account.select_disabled_text) {
        return {
          trigger: 'hover',
          content: account.select_disabled_text,
          container: false,
          classes: 'tooltip-dark'
        }
      } else {
        return null
      }
    },
    hasTimezoneError (account: { id: string }): boolean {
      return !!this.errors.find(_ => _.ad_account_id === account.id)?.fields.includes('timezone')
    },
    selectTimezone (account, timezone: Timezone): void {
      this.$emit('select-timezone', {
        id: account.id,
        timezone: timezone.id
      })
    },
    getAccountTimezone ({ id }: { id: string }): Timezone | null {
      const account = this.ad_accounts.find(_ => _.id === id)

      return account ? TIMEZONES.find(_ => _.id === account.timezone) || null : null
    },
    getLabelType (account) {
      switch (account.status) {
        case AccountConstants.STATUS_IMPORTED:
        case AccountConstants.STATUS_ACTIVE:
        case AccountConstants.STATUS_ANY_ACTIVE:
        case AccountConstants.STATUS_ACTIVE_ACTIVE:
        case AccountConstants.STATUS_ENABLE:
        case AccountConstants.STATUS_ENABLED:
        case AccountConstants.STATUS_ACCEPTED:
          return 'success'
        case AccountConstants.STATUS_PENDING_RISK_REVIEW:
        case AccountConstants.STATUS_PENDING_SETTLEMENT:
        case AccountConstants.STATUS_IN_GRACE_PERIOD:
        case AccountConstants.STATUS_UPDATING_INFO:
        case AccountConstants.STATUS_PENDING:
        case AccountConstants.STATUS_PENDING_VERIFIED:
        case AccountConstants.STATUS_SELF_SERVICE_UNAUDITED:
        case AccountConstants.STATUS_PENDING_CONFIRM:
        case AccountConstants.STATUS_PENDING_CONFIRM_MODIFY:
        case AccountConstants.STATUS_WAIT_FOR_BPM_AUDIT:
        case AccountConstants.STATUS_WAIT_FOR_PUBLIC_AUTH:
        case AccountConstants.STATUS_CONTRACT_PENDING:
        case AccountConstants.STATUS_UNKNOWN:
        case AccountConstants.STATUS_CANCELED:
        case AccountConstants.STATUS_SUSPENDED:
        case AccountConstants.STATUS_BILLING_HOLD:
          return 'warning'
        case AccountConstants.STATUS_UNAUTHENTICATED:
        case AccountConstants.STATUS_UNSETTLED:
        case AccountConstants.STATUS_HASNT_PERMISSION:
        case AccountConstants.STATUS_DISABLED_ON_FACEBOOK:
        case AccountConstants.STATUS_DISABLED:
        case AccountConstants.STATUS_PENDING_CLOSURE:
        case AccountConstants.STATUS_CLOSED:
        case AccountConstants.STATUS_ANY_CLOSED:
        case AccountConstants.STATUS_NEED_RESEND_REQUEST:
        case AccountConstants.STATUS_ERROR:
        case AccountConstants.STATUS_DELETED:
        case AccountConstants.STATUS_DISABLE:
        case AccountConstants.STATUS_CONFIRM_FAIL:
        case AccountConstants.STATUS_CONFIRM_FAIL_END:
        case AccountConstants.STATUS_CONFIRM_MODIFY_FAIL:
        case AccountConstants.STATUS_LIMIT:
        case AccountConstants.STATUS_LIMIT_CODE:
        case AccountConstants.STATUS_CLOSED_CLOSED:
          return 'danger'
      }
    },
    async switchPage (pageNum: string) {
      this.page = pageNum || 1
      this.$emit('page', this.page)
    },
    userAccountStatusTitle (account) {
      return AccountConstants.STATUS_TITLE[account.status] ?? account.status_human
    },
    accountIsSelected (id: number): boolean {
      return !!this.selected_ad_accounts.find(a => a.id === id)
    }
  }
})
