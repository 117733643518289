<template>
  <div class="old-ui-pagination">
    <paginate
      :page-count="pageCount"
      :page-range="pageRange"
      :margin-pages="marginPages"
      :click-handler="clickCallback"
      :prev-text="'PREV'"
      :next-text="'NEXT'"
      :container-class="'pagination'"
      :prev-class="'prev'"
      :next-class="'next'"
      :page-class="customClass"
      :force-page="currentPage"
    >
    </paginate>
  </div>
</template>

<script>
import Vue from 'vue'
import Paginate from 'vuejs-paginate'
export default Vue.extend({
  name: 'BasePagination',
  props: {
    pageCount: {
      type: Number
    },
    pageRange: {
      type: Number
    },
    marginPages: {
      type: Number
    },
    page: {
      type: Number
    },
    customClass: {
      type: String,
      default: 'page-item'
    }
  },

  watch: {
    page () {
      this.currentPage = this.page
    }
  },

  data () {
    return {
      currentPage: 1
    }
  },

  mounted () {
    this.currentPage = this.page
  },

  components: { Paginate },
  methods: {
    clickCallback (pageNum) {
      this.$emit('getData', pageNum)
    }
  }
})
</script>

<style scoped lang="scss">

.old-ui-pagination::v-deep {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
  }
  .page-item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top: 1px solid #d2d6de;
    border-bottom: 1px solid #d2d6de;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
  .page-item.active {
    background: $blue-primary;
    color: #fff;
  }
  .prev,
  .next {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
  .prev {
    border: 1px solid #d2d6de;
    border-radius: 4px 0 0 4px;
    height: 30px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prev.disabled {
    opacity: 0.6;
    a {
      cursor: not-allowed;
    }
  }
  .next.disabled {
    opacity: 0.6;
    a {
      cursor: not-allowed;
    }
  }
  .next {
    border: 1px solid #d2d6de;
    border-radius: 0 4px 4px 0;
    height: 30px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
