



















































import Vue from 'vue'

import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import PageTable from '@/components/integration/Table/PageTable.vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SettingsAdServiceIntegrationsPages',
  components: {
    BasePagePreloader,
    Button,
    PageTable
  },
  data () {
    return {
      userData: [],
      service_name: 'facebook',
      loading: true,
      selectedPages: []
    }
  },
  methods: {
    ...mapActions('integrations', [
      'getServicePagesExternal',
      'add_pages'
    ]),
    ...mapActions('notifications', [
      'addNotification'
    ]),
    addPages () {
      this.add_pages({
        service: 'facebook',
        pages: this.selectedPages,
        token: this.d_token
      }).then(() => {
        this.$router.push({ name: 'Integrations', required: true })
      }).cacth(() => {
        this.addNotification({
          type: 'danger',
          body: 'Something went wrong. Please try again later'
        })
      })
    },
    togglePage (acc) {
      if (acc.event) {
        this.selectedPages.push(acc.page)
      } else {
        const ind = this.selectedPages.indexOf(this.selectedPages.find(v => v.id === acc.page.id))
        this.selectedPages.splice(ind, 1)
      }
    }
  },
  computed: {
    ...mapGetters('integrations', ['getFacebook']),
    counter () {
      return this.selectedPages.length
    },
    urlObject () {
      return new URL(window.location.href)
    },
    d_token () {
      return this.urlObject.searchParams.get('d_token')
    },
    isSuccess () {
        return this.$route.query?.success === '1'
    }
  },
  mounted () {
    if (!this.isSuccess) {
        this.$router.push({ name: 'Integrations' })
        return true
    }
    this.getServicePagesExternal({
      service: 'facebook',
      token: this.d_token
    }).then(() => {
      this.loading = false
    })
  }
})
