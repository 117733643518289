













































import Vue from 'vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import * as AccountConstants from '@/constants/AdAccount'

export default Vue.extend({
  components: { BaseLabelTag },
  props: {
    selected_ad_accounts: {
      required: true,
      type: Array
    },
    service: {
      required: true,
      type: String
    },
    ad_accounts: {
      required: true,
      type: Object
    },
    pagination: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      page: 1,
      componentKey: 0,
      mainSelect: false,
      open: false
    }
  },
  watch: {
    selected () {
      this.rename()
    }
  },
  computed: {
    accounts () {
      return this.ad_accounts.clients
    }
  },
  methods: {
    openManager () {
      this.open = !this.open
    },
    getLabelType (account) {
      switch (account.status) {
        case AccountConstants.STATUS_IMPORTED:
        case AccountConstants.STATUS_ACTIVE:
        case AccountConstants.STATUS_ANY_ACTIVE:
        case AccountConstants.STATUS_ACTIVE_ACTIVE:
        case AccountConstants.STATUS_ENABLE:
        case AccountConstants.STATUS_ENABLED:
          return 'success'
        case AccountConstants.STATUS_PENDING_RISK_REVIEW:
        case AccountConstants.STATUS_PENDING_SETTLEMENT:
        case AccountConstants.STATUS_IN_GRACE_PERIOD:
        case AccountConstants.STATUS_UPDATING_INFO:
        case AccountConstants.STATUS_PENDING:
        case AccountConstants.STATUS_PENDING_VERIFIED:
        case AccountConstants.STATUS_SELF_SERVICE_UNAUDITED:
        case AccountConstants.STATUS_PENDING_CONFIRM:
        case AccountConstants.STATUS_PENDING_CONFIRM_MODIFY:
        case AccountConstants.STATUS_WAIT_FOR_BPM_AUDIT:
        case AccountConstants.STATUS_WAIT_FOR_PUBLIC_AUTH:
        case AccountConstants.STATUS_CONTRACT_PENDING:
        case AccountConstants.STATUS_UNKNOWN:
        case AccountConstants.STATUS_CANCELED:
        case AccountConstants.STATUS_SUSPENDED:
          return 'warning'
        case AccountConstants.STATUS_UNAUTHENTICATED:
        case AccountConstants.STATUS_UNSETTLED:
        case AccountConstants.STATUS_HASNT_PERMISSION:
        case AccountConstants.STATUS_DISABLED_ON_FACEBOOK:
        case AccountConstants.STATUS_DISABLED:
        case AccountConstants.STATUS_PENDING_CLOSURE:
        case AccountConstants.STATUS_CLOSED:
        case AccountConstants.STATUS_ANY_CLOSED:
        case AccountConstants.STATUS_NEED_RESEND_REQUEST:
        case AccountConstants.STATUS_ERROR:
        case AccountConstants.STATUS_DELETED:
        case AccountConstants.STATUS_DISABLE:
        case AccountConstants.STATUS_CONFIRM_FAIL:
        case AccountConstants.STATUS_CONFIRM_FAIL_END:
        case AccountConstants.STATUS_CONFIRM_MODIFY_FAIL:
        case AccountConstants.STATUS_LIMIT:
        case AccountConstants.STATUS_CLOSED_CLOSED:
          return 'danger'
      }
    },
    accountIsSelected (id: number): boolean {
      return !!this.selected_ad_accounts.find(a => a.id === id)
    }
  }
})
